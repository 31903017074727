<template>
    <div>
      <div class="page-container" style="background:#F8F4F0">
        
        <div class="text-h4 font-weight-bold primary-text text-center">合作伙伴</div>
        <img width="100%" height="auto" src="@/assets/images/partner.png" class="d-block mt-15" />
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue';

export default {
  components: {
    Footer
  },
  mounted(){
  },
  methods: {
  }
}
</script>

<style>
  .page-container {
    min-height: calc(100vh - 289px);
    padding: 120px;
    background: #F8F4F0;
  }

  @media screen and (max-width: 960px) {
    .page-container {
        min-height: calc(100vh - 266px);
        padding: 30px 24px;
    }
}
</style>
  